import empty from "../../../empty";
import partnero from "../../../partnero";

export default function _wix(action, data, programId) {

    const _action = (parent, data, programId) => {
    }

    if (partnero.settingsLoaded()) {
        return _action(this, data, programId);
    } else {
        partnero.poQueue(programId, ['integration', 'wix', data, programId]);
    }

    return empty;
}
