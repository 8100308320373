import empty from "../../../empty";
import partnero from "../../../partnero";
import axios from "axios";

export default function _register_click(action, data, programId) {

    const registerClickAction = function (self, data, programId) {

        let sendData = data;
        programId = partnero.getProgramId(programId);
        sendData.data = sendData.data ?? {};

        const host = self.$parent.$parent.$parent.host;
        if (host) {
            axios.post(`${host}/js/${programId}/analytics/register_click`, sendData);
        }
    }

    if (partnero.settingsLoaded(programId)) {
        return registerClickAction(this, data, programId);
    } else {
        partnero.poQueue(programId, ['analytics', 'register_click', data, programId]);
    }

    return empty;
}
