import empty from "../../../empty";
import partnero from "../../../partnero";

export default function _oncehub(action, data, programId) {

    // Helper function to get URL parameters
    function getUrlParameter(name) {
        name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
        var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
        var results = regex.exec(location.search);
        return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
    }

    const _action = (parent, data, programId) => {
        // Extract parameters from the URL using the helper function
        const inviteeEmail = getUrlParameter('Email');
        const inviteeFullName = getUrlParameter('Name');

        if (inviteeEmail) {
            let customerData = {
                key: inviteeEmail,
                email: inviteeEmail,
                name: inviteeFullName || null,
            };
            if (partnero.addAffiliateKey(programId, customerData)) {
                po('customers', 'signup', { data: customerData }, programId);
            }
        }
    }

    if (partnero.settingsLoaded()) {
        return _action(this, data, programId);
    } else {
        partnero.poQueue(programId, ['integration', 'oncehub', data, programId]);
    }

    return empty;
}
