import router from "../../../../router";
import host from "./host";
import url_format from "./url_format";
import cookie_time from "./cookie_time";
import link_param from "./link_param";
import query_param from "./query_param";
import simple_tracking_enabled from "./simple_tracking_enabled";
import direct_tracking from "./direct_tracking";
import portal_widget_config from "./portal_widget_config";
import utm_tags_config from "./utm_tags_config";

export default function settings() {
    return router({
        host,
        url_format,
        cookie_time,
        link_param,
        query_param,
        simple_tracking_enabled,
        direct_tracking,
        portal_widget_config,
        utm_tags_config,
    });
}
