import empty from "../../../empty";
import partnero from "../../../partnero";
import axios from "axios";

export default function _create(action, data, programId) {

    const createAction = function (self, data, programId) {

        let sendData = data;
        programId = partnero.getProgramId(programId);

        sendData.data.customer = sendData.data.customer ?? {};
        partnero.addAffiliateKey(programId, sendData.data.customer);

        const host = self.$parent.$parent.$parent.host;
        if (host) {
            axios.post(`${host}/js/${programId}/transactions`, sendData);
        }
    }

    if (partnero.settingsLoaded(programId)) {
        return createAction(this, data, programId);
    } else {
        partnero.poQueue(programId, ['transactions', 'create', data, programId]);
    }

    return empty;
}
