import empty from "../../../empty";
import partnero from "./../../../partnero";
import loadScript from 'simple-load-script';

export default function load() {

    const programId = this.$parent.id;
    const assets_host = this.$parent.$parent.$parent.assets_host;
    const debug = this.$parent.$parent.$parent.debug ?? false;

    partnero.loadProgram(programId);

    let dd = '?v' + (debug ? (new Date().getTime()) : (~~(new Date().getTime() / 1e6)));
    loadScript(`${assets_host}/program/${programId}/settings/settings.js` + dd)
        .finally(() => {
            partnero.programLoaded(programId);
            partnero.processPoQueue(programId);
        });

    return empty;
}
