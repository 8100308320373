import empty from "../../../empty";
import partnero from "../../../partnero";

export default function _gohighlevel(action, data, programId) {

    const _action = (parent, data, programId) => {
        $(document).on("mousedown touchstart", ".container-order-form-two-step .form-btn", function () {
            const nameValue = $('input[name="name"]').val();
            const emailValue = $('input[name="email"]').val();
            if (emailValue) {
                let customerData = {key: emailValue, name: nameValue ?? null};
                if (partnero.addAffiliateKey(programId, customerData)) {
                    po('customers', 'signup', {data: customerData}, programId);
                }
            }
        });
    }

    if (partnero.settingsLoaded()) {
        return _action(this, data, programId);
    } else {
        partnero.poQueue(programId, ['integration', 'gohighlevel', data, programId]);
    }

    return empty;
}
