import empty from "../../../empty";
import partnero from "../../../partnero";

export default function _universal(action, data, programId) {

    const getForms = (data) => {

        let forms = [];
        const formId = data?.form ?? null;

        if (formId) {
            const formIds = [formId];
            const allForms = document.querySelectorAll('form');
            allForms.forEach(form => {
                if (formIds.includes(form.id)) {
                    forms.push(form);
                }
            });
        } else {
            forms = document.querySelectorAll('form');
        }

        return forms;
    }

    const _action = (parent, data, programId) => {
        getForms(data).forEach(function (form) {
            form.addEventListener('submit', function (event) {
                try {
                    let poSubmit = false;
                    if (form.dataset.partnero_submitted === 'true') {
                        return;
                    }

                    event.preventDefault();

                    let emailInput = form.querySelector('input[name="email"]');

                    if (!emailInput) {
                        emailInput = form.querySelector('input[type="email"]');
                        if (!emailInput) {
                            emailInput = form.querySelector('input[name="data[email]"]');
                        }
                    }

                    if (emailInput) {
                        let nameValue = null;
                        const emailValue = emailInput.value;

                        if (emailValue && /\S+@\S+\.\S+/.test(emailValue)) {
                            let nameInput = form.querySelector('input[name="name"]');

                            if (!nameInput) {
                                nameInput = form.querySelector('input[name="first_name"]');
                                if (!nameInput) {
                                    nameInput = form.querySelector('input[name="firstName"]');
                                    if (!nameInput) {
                                        nameInput = form.querySelector('input[name="nombre"]');
                                    }
                                }
                            }

                            if (nameInput) {
                                nameValue = nameInput.value;
                            }

                            let customerData = {
                                key: emailValue,
                                name: nameValue,
                                email: emailValue,
                                options: {
                                    integration: 'po_universal',
                                    referral_url: partnero.getReferralUrlFromCookie()
                                }
                            };

                            if (partnero.addAffiliateKey(programId, customerData)) {
                                poSubmit = true;
                                po('customers', 'signup', {data: customerData}, programId);
                                form.dataset.partnero_submitted = 'true';
                                setTimeout(function () {
                                    form.submit();
                                }, 1000);
                            }
                        }
                    }

                    form.dataset.partnero_submitted = 'true';

                    if (poSubmit !== true) {
                        form.submit();
                    }
                } catch (error) {
                    console.error(error);
                }
            });
        });
    }

    if (partnero.settingsLoaded()) {
        return _action(this, data, programId);
    } else {
        partnero.poQueue(programId, ['integration', 'universal', data, programId]);
    }

    return empty;
}
