import ctx from "./ctx";
import visitor from "./visitor";
import settings from "./settings";
import load from "./load";
import router from "../../../router.js";
import type from "./type";
import portal_widget from "./portal_widget";
import utm_tags from "./utm_tags";

export default function _id(id) {
    this.id ??= id;
    return router({ctx, settings, visitor, load, type, portal_widget, utm_tags});
}
