import axios from "axios";
import {v4 as uuid} from "uuid";
import Cookies from "js-cookie";
import partnero from "../../../partnero";

export default function visitor(nnn) {

    const programId = this.$parent.id;
    const href = window.location.href;
    const debug = this.$parent.$parent.$parent.debug ?? false;
    const directTracking = partnero.getConfig('direct_tracking', programId);
    const partnerKey = partnero.getReferralKeyFromUrl(programId, href);

    let directTrackingPartnerKey = null;

    if (!partnerKey) {
        directTrackingPartnerKey = partnero.getDirectTrackingReferralKeyFromUrl(programId, href);
    }

    const sessionUuid = Cookies.get('partnero_session_uuid') || uuid();

    const domainParts = location.hostname.split('.');
    const domainPartsCount = domainParts.length;
    let domain = (domainPartsCount > 1 ? domainParts[domainPartsCount - 2] + '.' : '') + domainParts[domainPartsCount - 1];

    const ccTLDsList = ['co.uk', 'ac.uk', 'com.au', 'gov.au', 'edu.au', 'org.au', 'co.at', 'or.at', 'avocat.fr', 'aeroport.fr', 'co.nz', 'org.nz', 'co.in', 'gov.in', 'edu.in', 'co.jp', 'ac.jp', 'com.ru', 'org.ru', 'net.ru', 'co.za', 'co.kr', 'or.kr', 'com.es', 'org.es', 'com.lk', 'org.lk', 'co.th', 'or.th', 'com.tr', 'edu.tr', 'com.ua', 'edu.ua', 'ca.us', 'ny.us', 'com.pl', 'com.br', 'platformsh.site', 'com.mx'];
    if (ccTLDsList.includes(domain) && domainPartsCount > 2) {
        const glueParts = [domainParts[domainPartsCount - 3], domainParts[domainPartsCount - 2], domainParts[domainPartsCount - 1]];
        domain = glueParts.join('.');
    }

    const {hostname} = location;
    const {screen: {width, height}, navigator: {language}} = window;

    if (debug) {
        console.log('partnero: domain: ', domain);
        console.log('partnero: program id: ', programId);
        console.log('partnero: partner key: ', partnerKey);
        console.log('partnero: sessionUuid: ', sessionUuid);
        console.log('partnero: direct tracking: ', directTracking);
        console.log('partnero: direct tracking partner key: ', directTrackingPartnerKey);
    }

    // extend lifetime of cookie even if it's been set
    Cookies.set('partnero_session_uuid', sessionUuid, {expires: 3650, domain: domain, SameSite: 'None', Secure: true});

    const host = this.$parent.$parent.$parent.host;
    const simplePageViewsTrackingEnabled = partnero.getConfig('simple_tracking_enabled', programId);
    let currentRef = document.referrer;

    let data = {
        pub_id: programId,
        url: href,
        session_uuid: sessionUuid,
        hostname: hostname,
        screen: `${width}x${height}`,
        language: language,
        referrer: currentRef,
        pageType: 'simple_visit'
    };

    const programType = partnero.getConfig('type', programId);
    const cookieNameByProgramType = partnero.getCookieNameByProgramType(programType);

    if (debug) {
        console.log('partnero: program type: ', programType);
    }

    if (partnerKey) {

        partnero.setReferralKey(partnerKey, programId);

        if (debug) {
            console.log('partnero: cookie name: ', cookieNameByProgramType);
            console.log('partnero: setting partner key cookie');
        }

        Cookies.set(cookieNameByProgramType, partnerKey, {
            expires: partnero.getConfig('cookie_time', programId),
            domain: domain,
            SameSite: 'None',
            Secure: true
        });

        Cookies.set('referral_url', href, {
            expires: partnero.getConfig('cookie_time', programId),
            domain: domain,
            SameSite: 'None',
            Secure: true
        });

        data = {
            ...data,
            partner: partnerKey,
            pageType: 'referred_url',
            directTracking: !!directTrackingPartnerKey
        };

        const queryParams = Object.keys(data).map(function (key) {
            return key + '=' + encodeURIComponent(data[key]);
        }).join('&');

        function sendAnalyticsData() {

            if (data.pageType === 'simple_visit' && !simplePageViewsTrackingEnabled) {
                return;
            }

            if (debug) {
                console.log('partnero: tracking pixel request');
            }

            (new Image()).src = `${host}/analytics/pageview?${queryParams}`;
        }

        sendAnalyticsData();
    } else if (directTracking && directTrackingPartnerKey) {
        axios.post(`${host}/direct_tracking`, {
                key: directTrackingPartnerKey,
                pub_id: programId,
                url: href,
                session_uuid: sessionUuid,
                hostname: hostname,
                screen: `${width}x${height}`,
                language: language,
                referrer: currentRef,
                pageType: 'direct_tracking'
            }
        ).then(response => {
            const directLinkReferralKey = response.data.referral_key || null;
            if (directLinkReferralKey) {
                Cookies.set(cookieNameByProgramType, directLinkReferralKey, {
                    expires: partnero.getConfig('cookie_time', programId),
                    domain: domain,
                    SameSite: 'None',
                    Secure: true
                });

                Cookies.set('referral_url', href, {
                    expires: partnero.getConfig('cookie_time', programId),
                    domain: domain,
                    SameSite: 'None',
                    Secure: true
                });
            }

            if (!!response.data.is_redirect && (response.data.redirect_url || null)) {
                window.location.href = response.data.redirect_url;
            }
        }).catch(error => {
            console.log(error)
        });

    } else if (debug) {
        console.log('partnero: referral key not found');
    }
}
