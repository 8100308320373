import partnero from './../../../partnero';

export default function utm_tags() {
    const utmTags = partnero.getConfig('utm_tags_config', this.$parent.id);

    if (utmTags) {
        let url = new URL(window.location.href);

        if (
            (utmTags.campaign_name && !url.searchParams.has('utm_campaign'))
            || (utmTags.campaign_term && !url.searchParams.has('utm_term'))
            || (utmTags.campaign_medium && !url.searchParams.has('utm_medium'))
            || (utmTags.campaign_source && !url.searchParams.has('utm_source'))
            || (utmTags.campaign_content && !url.searchParams.has('utm_content'))
        ) {
            if (utmTags.campaign_name) url.searchParams.set('utm_campaign', utmTags.campaign_name);
            if (utmTags.campaign_term) url.searchParams.set('utm_term', utmTags.campaign_term);
            if (utmTags.campaign_medium) url.searchParams.set('utm_medium', utmTags.campaign_medium);
            if (utmTags.campaign_source) url.searchParams.set('utm_source', utmTags.campaign_source);
            if (utmTags.campaign_content) url.searchParams.set('utm_content', utmTags.campaign_content);

            window.location.replace(url.toString());
            return;
        }
    }

    window.dispatchEvent(new Event('partnero.utmTagsProcessed'));
}


