import empty from "../../../empty";
import partnero from "../../../partnero";

export default function _simvoly(action, data, programId) {

    const _action = (parent, data, programId) => {
        document.addEventListener("mousedown", handleEvent);
        document.addEventListener("touchstart", handleEvent);

        function handleEvent(event) {
            const target = event.target;
            if (target.matches('.checkout-info-inner a.btn[data-role="next-step"], .checkout-info-inner a.btn[data-role="continue"]')) {
                handleClick(target);
            }
        }

        function handleClick(target) {
            const nameInputs = document.querySelectorAll('input[name="name"]');
            const emailInputs = document.querySelectorAll('input[name="email"]');
            let nameValue = "";
            let emailValue = "";

            // Iterate through all name inputs to find the first one with a value
            for (let input of nameInputs) {
                if (input.value.trim() !== "") {
                    nameValue = input.value.trim();
                    break;
                }
            }

            // Iterate through all email inputs to find the first one with a value
            for (let input of emailInputs) {
                if (input.value.trim() !== "") {
                    emailValue = input.value.trim();
                    break;
                }
            }

            if (emailValue) {
                let customerData = { key: emailValue, email: emailValue, name: nameValue || null };
                if (partnero.addAffiliateKey(programId, customerData)) {
                    po('customers', 'signup', { data: customerData }, programId);
                }
            }
        }

    }

    if (partnero.settingsLoaded()) {
        return _action(this, data, programId);
    } else {
        partnero.poQueue(programId, ['integration', 'simvoly', data, programId]);
    }

    return empty;
}
