import empty from "../../../empty";
import partnero from "../../../partnero";
import axios from "axios";

export default function _signup(action, data, programId) {

    const signupAction = function (self, data, programId) {

        let sendData = data;
        programId = partnero.getProgramId(programId);

        sendData.data = sendData.data ?? {};
        partnero.addAffiliateKey(programId, sendData.data);

        sendData.data.options = sendData.data.options ?? {};
        sendData.data.options.integration = 'po_customers_signup';
        if (!sendData.data.options.hasOwnProperty('referral_url')) {
            sendData.data.options.referral_url = window.location.href;
        }

        const host = self.$parent.$parent.$parent.host;
        if (host) {
            axios.post(`${host}/js/${programId}/customers`, sendData);
        }
    }

    if (partnero.settingsLoaded(programId)) {
        return signupAction(this, data, programId);
    } else {
        partnero.poQueue(programId, ['customers', 'signup', data, programId]);
    }

    return empty;
}
