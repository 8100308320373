import getPo from "./getPo";
import Cookies from "js-cookie";

export default {
    programs: {},
    defaultProgramId: null,
    programDefault: {
        id: null,
        config: {
            type: null,
            host: null,
            assets_host: null,
            query_param: 'ref',
            link_param: 'link',
            url_format: 'query',
            simple_tracking_enabled: true,
            direct_tracking: false,
            cookie_time: 30 * 24 * 60 * 60,
            portal_widget_config: {},
        },
        referralKey: null,
        settingsLoaded: false,
        poQueue: []
    },

    loadProgram(programId) {
        this.defaultProgramId = this.defaultProgramId ?? programId;
        this.getProgram(programId);
    },

    programLoaded(programId) {
        this.getProgram(programId).settingsLoaded = true;
        po('program', programId, 'visitor');
        po('program', programId, 'portal_widget');
        po('program', programId, 'utm_tags');
    },

    getProgramId(programId) {
        return programId ?? this.defaultProgramId;
    },

    getProgram(programId) {
        programId = this.getProgramId(programId);

        if (!programId) {
            throw new Error('Program ID is not set');
        }

        if (!this.programs[programId]) {
            this.programs[programId] = JSON.parse(JSON.stringify(this.programDefault));
        }

        this.programs[programId].id = programId;
        return this.programs[programId];
    },

    setReferralKey(referralKey, programId) {
        this.getProgram(programId).referralKey = referralKey;
        return this;
    },

    settingsLoaded(programId) {
        return this.getProgram(programId).settingsLoaded;
    },

    setSettingsLoaded(settingsLoaded, programId) {
        this.getProgram(programId).settingsLoaded = settingsLoaded;
        return this;
    },

    setConfig(name, value, programId) {
        this.getProgram(programId).config[name] = value;
        return this;
    },

    getConfig(name, programId = null, def = null) {
        return this.getProgram(programId).config[name] ?? def;
    },

    poQueue(programId, args) {
        this.getProgram(programId).poQueue.push(args);
    },

    processPoQueue(programId) {
        const po = getPo();
        const program = this.getProgram(programId);

        while (program.poQueue.length > 0) {
            const args = program.poQueue.shift();
            po(...args);
        }
    },

    getReferralKeyFromCookie(programId) {
        let referralKey = null;
        const type = this.getConfig('type', programId);
        if (type) {
            const cookieName = this.getCookieNameByProgramType(type);
            referralKey = Cookies.get(cookieName);
        }

        return referralKey;
    },

    getReferralUrlFromCookie() {
        return Cookies.get('referral_url');
    },

    getReferralKeyFromUrl(programId, href) {

        let referralKey = null;

        const url_format = this.getConfig('url_format', programId);
        const link_param = this.getConfig('link_param', programId);
        const query_param = this.getConfig('query_param', programId);

        if (url_format && link_param && query_param && href) {
            switch (url_format) {
                case 'link':
                    const reg = new RegExp(`\\/${link_param}\\/([\\w]*)`);
                    const matches = href.match(reg);
                    referralKey = matches?.[1];
                    break;
                case 'query':
                default:
                    const url = new URL(href);
                    referralKey = url.searchParams.get(query_param);
                    break;
            }
        }

        return referralKey;
    },

    getDirectTrackingReferralKeyFromUrl(programId, href) {
        if (this.getConfig('direct_tracking', programId, false)) {
            try {
                const urlObj = new URL(href);
                const pathSegments = urlObj.pathname.split('/').filter(Boolean);

                if (pathSegments.length === 1) {
                    return pathSegments[0];
                }
            } catch (error) {
                return null;
            }
        }

        return null;
    },

    getCookieNameByProgramType(programType) {
        switch (programType) {
            case 'refer_a_friend':
                return 'partnero_referral';
            case 'affiliate':
                return 'partnero_partner';
            default:
                return 'partnero_partner';
        }
    },

    addAffiliateKey(programId, sendData) {

        let keyName = null;
        let referralKey = null;
        programId = this.getProgramId(programId);

        referralKey = this.getReferralKeyFromUrl(programId, window.location.href) ?? this.getReferralKeyFromCookie(programId);
        referralKey = referralKey ?? this.getDirectTrackingReferralKeyFromUrl(programId, window.location.href);

        if (referralKey) {
            const type = this.getConfig('type', programId);
            switch (type) {
                case 'affiliate':
                    keyName = 'partner';
                    break;
                case 'refer_a_friend':
                    keyName = 'referring_customer';
                    break;
            }

            if (keyName) {
                sendData[keyName] = sendData[keyName] ?? {};
                if (!sendData[keyName].hasOwnProperty('key')) {
                    sendData[keyName].key = referralKey;
                    return true;
                }
            }
        }

        return false;
    },
}
