import empty from "../../../empty";
import partnero from "../../../partnero";

export default function _boomerangme(action, data, programId) {

    const _action = (parent, data, programId) => {
        document.addEventListener("mousedown", handleEvent);
        document.addEventListener("touchstart", handleEvent);

        function handleEvent(event) {
            const target = event.target;
            if (target.matches('#root form button[type="submit"]')) {
                handleClick(target);
            }
        }

        function handleClick(target) {
            const nameValue = document.querySelector('input[name="name"]').value;
            const emailValue = document.querySelector('input[name="email"]').value;
            if (emailValue) {
                let customerData = { key: emailValue, name: nameValue || null };
                if (partnero.addAffiliateKey(programId, customerData)) {
                    po('customers', 'signup', { data: customerData }, programId);
                }
            }
        }
    }

    if (partnero.settingsLoaded()) {
        return _action(this, data, programId);
    } else {
        partnero.poQueue(programId, ['integration', 'boomerangme', data, programId]);
    }

    return empty;
}
