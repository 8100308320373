import settings from "./settings";
import customers from "./customers";
import analytics from "./analytics";
import integration from "./integration";
import transactions from "./transactions";
import program from "./program";
import router from "../router";

export default function (host) {
    this.host = host;
    return router({settings, program, customers, transactions, analytics, integration});
}
