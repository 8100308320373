export default function callable(ctx, realFn) {
    const wrappedFn = function () {
        const args = Array.prototype.slice.call(arguments);

        let tmpFn = realFn;
        let parentCtx = ctx;

        args.forEach(function (arg) {
            const mapKey = JSON.stringify(arg);
            tmpFn.ctxMap = tmpFn.ctxMap || {};
            const ctx = (tmpFn.ctxMap[mapKey] = tmpFn.ctxMap[mapKey] || {});
            ctx.$parent = ctx.$parent || parentCtx;

            parentCtx = ctx;

            if (tmpFn.routeFn ?? false) {
                let callArgs = args.splice(1);
                tmpFn = tmpFn.call(ctx, ...callArgs);
            } else {
                tmpFn = tmpFn.call(ctx, arg);
            }
        });

        return callable(parentCtx, tmpFn);
    };

    return wrappedFn;
}
