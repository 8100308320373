import empty from "../../../../empty";
import partnero from "../../../../partnero";

export default function host() {
    return function _host(host) {
        this.$parent.$parent.$parent.host = host;
        partnero.setConfig('host', host, this.$parent.$parent.$parent.id);
        return empty;
    };
}
